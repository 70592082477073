<template>
  <CModal id="OrderSendNotify" class="ActionModel" size="lg" :show.sync="Toggle" :centered="true" @update:show="HandleHideModal">
    <CInput :label="'收件人'" :value="`${MemberInfo.Name}(${MemberInfo.Email})`" horizontal readonly />
    <CInput :label="'主旨'" v-model="Title" horizontal />
    <CRow form class="form-group">
      <CCol tag="label" sm="3" class="col-form-label">
        訊息內容
      </CCol>
      <CCol sm="9">
        <Editor api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="Content" />
      </CCol>
    </CRow>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.SendNotifyMessage') }}</h5>
        <CButtonClose @click="HandleHideModal()" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="secondary">{{ $t('Global.Close') }}</CButton>
        <CButton @click="Send()" color="success" :disabled="(Content === '' || Title === '')">{{ $t('Global.Submit') }}</CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'
export default {
  name: "OrderSendNotify",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    MemberInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    OrderData: {
      type: Object,
      default () {
        return {}
      }
    },
    MessageType: {
      type: String,
      default: 'Custom'
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      Title: '',
      Content: '',
      Template: 'Default',
      Loading: false,
      MessageTypeOptions: {
        Custom: {
          Title: '',
          Content: ''
        },
        Unpaid: {
          Title: '訂單未付款通知',
          Content: `<p>您的訂單${this.OrderData.OrderNum}至今仍尚未付款，請您抽空進行付款。</p><p>訂單詳細資訊可於以下<a href="https://${this.$store.state.user.permission.Domain}/cart/confirm?id=${this.OrderData._id}" target="_blank">連結</a>查看:<br>https://${this.$store.state.user.permission.Domain}/cart/confirm?id=${this.OrderData._id}</p>`
        }
      },
      TinyMCE: TinyMCESetting
    }
  },
  computed: {
  },
  mounted() {
    if (typeof this.MessageTypeOptions[this.MessageType] === 'object') {
      this.Title = this.MessageTypeOptions[this.MessageType].Title
      this.Content = this.MessageTypeOptions[this.MessageType].Content
    }
  },
  watch: {
  },
  methods: {
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    },
    Send() {
      const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!EmailRegex.test(this.MemberInfo.Email)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/WrongEmailFormat'),
          type: 'error'
        })
        return false
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/emailNotify/single/send',
        method: 'post',
        data: {
          Title: this.Title,
          CustomContent: {
            Title: this.Title,
            Content: this.Content
          },
          Recipient: {
            Email: this.MemberInfo.Email,
            Name: this.MemberInfo.Name
          }
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Message/SendSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/SendFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#OrderSendNotify {
  label {
    font-weight: bold;
  }
}
</style>
